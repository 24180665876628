import React from 'react'

import withContext from '../../lib/withContext'
import SEO from '../../components/SEO'

const lastUpdated = '20 September 2018'
const email = 'legal@jollycons.com'

const metaData = {
  title: 'Privacy',
  image: undefined
}

const Privacy = () => (
  <div>
    <SEO metaData={metaData} />
    <article className="container-md grid center privacy margin-xl-top animated fadeInPage">
      <section className="col-12">
        <div className="col-8 intro margin-xl-bottom">
          <h1>Privacy</h1>
          <p>
            {'At Jollycons we care about your privacy. We want to inform you about your data, what we collect and what we do with it. We try to make this policy as easy to understand as possible. If you have questions after reading the privacy policy feel free to send us a email.'}
          </p>
        </div>
      </section>

      <section className="col-7 margin-s-bottom">
        <h3>What data do we collect?</h3>
        <p>
          {'While using Jollycons we might collect personal information. The table below demonstrates what data we collect and when we collect it. (This does not include data collected by cookies)'}
        </p>
      </section>

      <section className="col-11 margin-m-bottom">
        <table className="col-12 privacy-table">
          <tbody>
            <tr className="table__header">
              <th>Purpose</th>
              <th>What data</th>
              <th>Shared with</th>
              <th>Retention period</th>
              <th>Collected when</th>
            </tr>
            <tr>
              <td className="usage">
                {'This data is used to process your order and cart. And is required for making a purchase.'}
              </td>
              <td className="kind">
                <ul>
                  <li>Name</li>
                  <li>Shipping and billing addresses</li>
                  <li>IP address</li>
                  <li>Email</li>
                  <li>Payment details</li>
                  <li>Information from Shopify cookies</li>
                  <li>Information about the orders customers initiate</li>
                </ul>
              </td>
              <td className="shared-with">
                <ul>
                  <li>Shopify</li>
                  <li>Shopify might share data with third parties.</li>
                </ul>
              </td>
              <td className="retention">
                {'We save this data till you request deletion. This allows us to give you access to your files in case you lose them.'}
              </td>
              <td className="collected-when">
                {'Data may be collected when visiting the page, adding a item to your cart or at checkout.'}
              </td>
            </tr>
            <tr>
              <td className="usage">
                This data is used to send you updates when we release new icons.
              </td>
              <td className="kind">
                <ul>
                  <li>Email</li>
                  <li>IP</li>
                </ul>
              </td>
              <td className="shared-with">
                <ul>
                  <li>Campaign monitor</li>
                  <li>Campaign monitor might share data with third parties</li>
                </ul>
              </td>
              <td className="retention">
                Data doesn&apos;t get deleted
              </td>
              <td className="collected-when">
                You subscribe to our news letter.
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className="col-7 margin-m-bottom">
        <h3>How do we use your personal data?</h3>
        <p>
          {'At Jollycons we may use your personal data for the following purposes. These are examples and might not explain all the usages.'}
        </p>

        <ul className="primary success">
          <li>
            {'We use your personal data to be able to re-send you the files in case you lose them.'}
          </li>
          <li>We use your personal data to complete the transaction.</li>
          <li>To save the products in your cart for your next visit.</li>
          <li>To see what products you have bought.</li>
        </ul>
      </section>

      <section className="col-7 margin-m-bottom">
        <h3>How do we not use your personal data?</h3>
        <p>We would like to explain what we do not use your personal data for.</p>

        <ul className="primary warning">
          <li>We do not customize your experience.</li>
          <li>We do not customize the pricing or product shown to you.</li>
          <li>We do not show ads (based on your data).</li>
          <li>{'We don\'t sell your data.'}</li>
        </ul>
      </section>

      <section className="col-7 margin-m-bottom">
        <h3>How do we protect your information?</h3>
        <p>
          {'Your data is securely stored in secure servers of our third parties, we can access this data using our accounts of the respective services. To see how our third parties protect your information you can check their according policies.'}
        </p>
      </section>

      <section className="col-7 margin-m-bottom">
        <h3>Where do we store and process personal data?</h3>
        <p>
          {'We don\'t store personal data on our servers, the data we have is stored in our third parties databases. Personal data is processed through our third party providers such as Shopify.'}
        </p>
      </section>

      <section className="col-7 margin-s-bottom">
        <h3>Do we use &apos;cookies&apos;?</h3>
        <p>
          {'Yes, at Jollycons we use cookies to provide you the best experience possible. Cookies are small files that a site or its service provider transfers to your computer\'s hard drive through your web browser, that enables the site\'s or service provider\'s systems to recognize your browser and capture and remember certain information. You can find an overview of the cookies that we use in the table below.'}
        </p>
      </section>

      <section className="col-11 margin-m-bottom">
        <table className="col-12 privacy-table">
          <tbody>
            <tr className="table__header">
              <th>Cookies</th>
              <th>Purpose</th>
              <th>Data we collect</th>
              <th>Shared with</th>
            </tr>
            <tr>
              <td className="function">Analytic cookies</td>
              <td className="purpose">These cookies allow us to analyze the usage of our website. This allows us to get a better understanding of the usage and improve our website and services.</td>
              <td className="data">
                <ul>
                  <li>Time of visit</li>
                  <li>Page visited</li>
                  <li>Time spend on each page</li>
                  <li>Referring site details (how did the user get to our site)</li>
                  <li>Web browser</li>
                  <li>Operating system</li>
                  <li>Flash version</li>
                  <li>JavaScript support</li>
                  <li>Screen resolution</li>
                  <li>Screen color processing ability</li>
                  <li>Network location</li>
                </ul>
              </td>
              <td className="shared">
                <ul>
                  <li>Google</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="function">
                Third party cookies
              </td>
              <td className="purpose">
                This cookies are placed by third party providers such as Shopify.
                <br />
                {'These cookies can contain functional, analytic and advertisement cookies.'}
                <br />
                {'These are handled by Shopify and what type of cookies depend on your usage of Shopify in the past.'}
              </td>
              <td className="data">
                These are examples of data Shopify collects.
                <ul>
                  <li>Name</li>
                  <li>Shipping and billing addresses</li>
                  <li>IP address</li>
                  <li>Customer email or phone number</li>
                  <li>What page the user arrived from</li>
                  <li>How many time has the user visited the site</li>
                  <li>Device used</li>
                  <li>Browser used</li>
                  <li>Products stored in cart</li>
                </ul>
              </td>
              <td className="shared">
                <ul>
                  <li>Shopify</li>
                  <li>Shopify might share data with third parties such as Google.</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className="col-7 margin-l-bottom analytics">
        <h3>Analytics</h3>
        <p>
          {'We may use third-party Service Providers to monitor and analyze the use of our Service.'}
        </p>

        <h4>Google Analytics</h4>

        <p>
          {'Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.'}
        </p>

        <p>
          {'You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.'}
        </p>

        <p>
          {'We also respect the do not track setting of your browser, if you wish to not be tracked.'}
        </p>

        <p>
          {'For more information on the privacy practices of Google, please visit the Google Privacy & Terms Web page: '}
          <a
            href="https://policies.google.com/privacy?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=en
          </a>
        </p>
      </section>

      <section className="col-7 margin-m-bottom">
        <h3>Shopify</h3>
        <p>
          {'At Jollycons we use Shopify to handle our products and sales. Shopify therefor processes your user data. Shopify also places cookies in your browser, these are required for Jollycons to function properly. The type of cookies Shopify places depend on your previous interactions with Shopify.'}
        </p>

        <h4>Automated decision-making</h4>
        <p>
          {'Shopify might use your personal data for automated decision making in risk and fraud screening.'}
        </p>

        <p>
          {'In the course of offering our services, Shopify uses a number of machine learning algorithms and forms of automated decision-making. For example, we use automated decision-making: to prevent risk and fraud by merchants; to help merchants avoid fraudulent transactions from their customers; to personalize merchants’ experience when they use our admin and app store; and to determine eligibility for certain services (like Shopify Capital).'}
        </p>

        <p>
          {'Most of these algorithms (excluding the personalization features and a subset of customer risk/fraud screening, discussed in more detail below) are not fully automated and include some human intervention (for example, customer risk and fraud scores are provided to merchants, who must intentionally decide how to act on them). Our personalization algorithms are fully automated, but only affect display features like how apps in the app store are presented to you. Similarly, we have a small subset of fully automated fraud screening blacklists, which, if we believe a transaction was made using stolen or fraudulent payment information, may stop a customer from completing a transaction--but only for a period of between a few hours and a few days.'}
        </p>

        <h4>More information</h4>
        <p>
          {'You can read more details about Shopify and their privacy policy at the following pages.'}
        </p>
        <ul className="primary list__basic">
          <li>
            <a
              href="https://www.shopify.com/legal/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.shopify.com/legal/privacy
            </a>
          </li>
          <li>
            <a
              href="https://www.shopify.com/legal/cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.shopify.com/legal/cookies
            </a>
          </li>
        </ul>
      </section>

      <section className="col-7 margin-m-bottom">
        <h3>Campaign monitor</h3>
        <p>
          {'At Jollycons we use Campaign monitor to handle our news letter. Additionally to the data we collect when you sign up for the news letter. We also collect data when you open our email.'}
        </p>

        <p>The following data is collected when we send you a email.</p>
        <ul className="primary list__basic">
          <li>How many people reported it as spam.</li>
          <li>Amount of people who opened the mail</li>
          <li>Clicks in emails</li>
          <li>Shares in emails</li>
          <li>Percent of emails we send that you opened.</li>
          <li>The date you subscribed on.</li>
          <li>The IP you used when you subscribed.</li>
        </ul>

        <h4>More information</h4>
        <p>
          {'You can read more about Campaign monitor and their privacy policy at the following pages.'}
        </p>
        <ul className="primary list__basic">
          <li>
            <a
              href="https://www.campaignmonitor.com/policies/#privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.campaignmonitor.com/policies/#privacy-policy
            </a>
          </li>
          <li>
            <a
              href="https://www.campaignmonitor.com/policies/#cookie-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.campaignmonitor.com/policies/#cookie-policy
            </a>
          </li>
        </ul>
      </section>

      <section className="col-7 margin-m-bottom">
        <h3>Third-party disclosure</h3>
        <p>
          {'We do not share data with third parties with exception of the partners named above. To see what data is shared with third parties by our partners you can view their respective policies.'}
        </p>
      </section>

      <section className="col-7 margin-m-bottom">
        <h3>Third-party links</h3>
        <p>
          {'Our Website may contain links to other websites which are outside our control and are not covered by this Privacy Policy (“Third-Party Websites”). If you access Third-Party Websites using the links provided, the operators of such sites may collect information from you which will be used by them in accordance with their own privacy policies, which may differ from ours. We encourage you to review the privacy policies of those Third-Party Websites so that you understand if/how they collect and/or use information from you or your computer.'}
        </p>
      </section>

      <section className="col-7 margin-m-bottom">
        <h3>Children</h3>
        <p>
          {'Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information at our Site from those we actually know are under 16, and no part of our website is structured to attract anyone under 16. We also ask users under 16 to not use our services.'}
        </p>
      </section>

      <section className="col-7 margin-m-bottom">
        <h3>Your rights</h3>
        <p>
          {'As the user you have a lot of rights regarding your data. For your convenience we will list them here. We answer these requests as soon as possible.'}
        </p>

        <h4>View your data</h4>
        <p>
          {`You have the right to view your data. You can request this by sending us a email at ${email}`}
        </p>

        <h4>Deletion of your data</h4>
        <p>
          You have the right to request deletion of your data.
          {'By requesting deletion of your data you renounce your right to reclaim your product.'}
        </p>

        <p>
          {'Meaning if you lose the files you would have to buy them again to gain access to them again.'}
        </p>

        <p>
          {`To request deletion of data please send a email to ${email}. This mail should be send from the email you made the purchase from.`}
        </p>

        <p>The mail should address the following.</p>
        <ul className="primary list__basic">
          <li>What data you request to be deleted.</li>
          <li>
            {'That you understand and agree that by requesting deletion of data you are renouncing your right to reclaim the bought icons.'}
          </li>
          <li>That you are withdrawing consent to processing your personal data.</li>
        </ul>

        <h4>Correction (rectification) of your data</h4>
        <p>
          {'You have the right to request correction to your data if you made a mistake. For example if you typed your email wrong.'}
        </p>

        <p>
          {`For correction of data please send a email to ${email} from the email you made the purchase with. Also provide the correct data and proof that the new data is correct.`}
        </p>
      </section>

      <section className="col-7 margin-m-bottom">
        <h3>Questions?</h3>
        <p>
          {`We tried to make this privacy policy as easy to understand as possible. If you have any questions that are unanswered or are unsure about parts of this policy. Don't hesitate to get in touch ${email}`}
        </p>
      </section>

      <section className="col-7 margin-m-bottom">
        <h3>Full details</h3>
        <p>Jollycons is part of Yummygum. Our office is located at</p>
        <p>
          Jacob van Lennepkade 334-H
          <br />
          1053 NJ Amsterdam
          <br />
          The Netherlands
          <span role="img" aria-label="Dutch flag"> 🇳🇱</span>
        </p>

        <p><a href={`mailto:${email}`}>{email}</a></p>

        <p>
          {'Privacy policy was last updated at '}
          {lastUpdated}
        </p>
      </section>
    </article>
  </div>
)

export default withContext(Privacy)
